<template>
<form @submit.prevent="submitForm">
    <ion-card style=" background-color:#151516;
      background-size: cover;">
        <ion-card-content>
            <ion-input class="custom" placeholder="Telephone" style=" width:100%; color:white;margin:-5px;" type="number"  v-model="enteredUser" />
        </ion-card-content>
     </ion-card>

        <ion-card style=" background-color:#151516;
      background-size: cover;">
            <ion-card-content>
                <ion-input class="custom" placeholder="Mote de passe" style="width:100%; color:white;margin:-5px;" type="password" v-model="enteredPassword" />
            </ion-card-content>
        </ion-card>
        <ion-button class="ion-button4" type="submit" style="width:98%;height:50px "> OK</ion-button>
</form>
</template>

<script>
import {
    IonInput,
    IonButton,
    IonCard,
    IonCardContent,
} from '@ionic/vue';
export default {
    data() {
        return {
            enteredUser: '',
            enteredPassword: '',
        }
    },
    emits: ['get-admin'],
    methods: {
        submitForm() {
            const AdminData = {
                user: this.enteredUser,
                password: this.enteredPassword
            };
            this.$emit('get-admin', AdminData);
        },

    },
    components: {
        IonInput,
        IonButton,
        IonCard,
        IonCardContent,
    }
}
</script>

<style>
.ion-button4 {
    --background: #FFE01E;
    --opacity: 0.8;
    height: 40px;
    --width: 100%;
    color: #2D2D30;
    font-weight: 700;
    font-size: 15px;
    padding: 0px;
    --border-radius: 10px !important;
}
</style>
