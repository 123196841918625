<template>
<Base-Layout  PageTitle="BOUTIQUE"   page-default-back-Link="/accuiel"     >

<ion-page ref="page">
     <ion-content class="ion-padding" >
<br>
<br>
        <ion-row  >
          <ion-col size="6"  style="margin-left:-10px">
          <ion-card class="ion-card2"   style="--ion-background-color: #1d1d1f;height:200px;
          width:100%;--background: url(assets/images/produit.png) 0 0/100% 100% no-repeat;" id="Produit">
         </ion-card>
          </ion-col>
          <ion-col size="6"  >
            <ion-card class="ion-card2"   style="--ion-background-color: #1d1d1f;height:200px;
          width:100% ;--background: url(assets/images/produinutritionnel.png) 0 0/100% 100% no-repeat;" id="ProduitNutritionnel">
         </ion-card>
          </ion-col>
        </ion-row>
        <ion-row  >
          <ion-col size="6" style="margin-left:-10px">
          <ion-card class="ion-card2"   style="--ion-background-color: #1d1d1f;height:200px;
          width:100% ;--background: url(assets/images/boisson.png) 0 0/100% 100% no-repeat;" id="Boisson">
         </ion-card>
          </ion-col>
          <ion-col size="6">
            <ion-card class="ion-card2"   style="--ion-background-color: #1d1d1f;height:200px;
          width:100% ;--background: url(assets/images/repas.png) 0 0/100% 100% no-repeat;" id="Repas">
         </ion-card>
          </ion-col>
        </ion-row>









<!--Produit-->
      <ion-modal ref="Produit" trigger="Produit" :presenting-element="presentingElement">
        <ion-header>
          <ion-toolbar>
            <ion-title>
                Produit
                </ion-title>
            <ion-buttons slot="end">
              <ion-button @click="Produit()">Close</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="">
            <!--Item PRODUIT-->
                   <ion-card class="fontapp" style="--ion-background-color: #1d1d1f" v-for="produit in DataProduitSource" :key="produit.ID_PRODUIT">
                    <ion-card-content style="">
                      <img :src="`data:image/jpeg;base64,${produit.IMAGE_PRODUITbaze64}`"
                      style="object-fit: cover;width:80px;height:80px;float:left;
                        background-color:#131313;margin-bottom:20px ;margin-right:12px ;
                        border-radius:15px">
                        <span  class="second-text" style="font-size:14px" > <b>{{produit.DESIGNATION_PRODUIT}}</b></span><br>
                        <span  v-if="produit.IN_STOCK > 1">in stock </span><br>
                        <span  v-if="produit.IN_STOCK < 1">out of stock </span>
                        <span class="second-text" style="color:#fff600;font-size:18px" >
                          <b> {{produit.PRIX_VENTE_PRODUIT}}</b></span><br>
                    </ion-card-content>
                </ion-card>

        </ion-content>
      </ion-modal>

<!--ProduitNutritionnel-->
      <ion-modal ref="ProduitNutritionnel" trigger="ProduitNutritionnel" :presenting-element="presentingElement2">
        <ion-header>
          <ion-toolbar>
            <ion-title>ProduitNutritionnel</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="ProduitNutritionnel()">Close</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content  >
                   <!--Item PRODUIT-->
                   <ion-card class="fontapp" style="--ion-background-color: #1d1d1f" v-for="produit in DataProduitNutritionnel" :key="produit.ID_PRODUIT">
                    <ion-card-content style="">
                      <img :src="`data:image/jpeg;base64,${produit.IMAGE_PRODUITbaze64}`"
                      style="object-fit: cover;width:80px;height:80px;float:left;
                        background-color:#131313;margin-bottom:20px ;margin-right:12px ;
                        border-radius:15px">
                        <span  class="second-text" style="font-size:14px" > <b>{{produit.DESIGNATION_PRODUIT}}</b></span><br>
                        <span  v-if="produit.IN_STOCK > 1">in stock </span><br>
                        <span  v-if="produit.IN_STOCK < 1">out of stock </span>
                        <span class="second-text" style="color:#fff600;font-size:18px" >
                          <b> {{produit.PRIX_VENTE_PRODUIT}}</b></span><br>
                    </ion-card-content>
                </ion-card>
        </ion-content>
      </ion-modal>

      <!--Boisson-->
      <ion-modal ref="Boisson" trigger="Boisson" :presenting-element="presentingElement3">
        <ion-header>
          <ion-toolbar>
            <ion-title>Boisson</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="Boisson()">Close</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content  >
                   <!--Item PRODUIT-->
                   <ion-card class="fontapp" style="--ion-background-color: #1d1d1f" v-for="produit in DataBoisson" :key="produit.ID_PRODUIT">
                    <ion-card-content style="">
                      <img :src="`data:image/jpeg;base64,${produit.IMAGE_PRODUITbaze64}`"
                      style="object-fit: cover;width:80px;height:80px;float:left;
                        background-color:#131313;margin-bottom:20px ;margin-right:12px ;
                        border-radius:15px">
                        <span  class="second-text" style="font-size:14px" > <b>{{produit.DESIGNATION_PRODUIT}}</b></span><br>
                        <span  v-if="produit.IN_STOCK > 1">in stock </span><br>
                        <span  v-if="produit.IN_STOCK < 1">out of stock </span>
                        <span class="second-text" style="color:#fff600;font-size:18px" >
                          <b> {{produit.PRIX_VENTE_PRODUIT}}</b></span><br>
                    </ion-card-content>
                </ion-card>
        </ion-content>
      </ion-modal>

      <!--Repas-->
      <ion-modal ref="Repas" trigger="Repas" :presenting-element="presentingElement4">
        <ion-header>
          <ion-toolbar>
            <ion-title>Repas</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="Repas()">Close</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content >
                   <!--Item PRODUIT-->
                   <ion-card class="fontapp" style="--ion-background-color: #1d1d1f" v-for="produit in DataRepas" :key="produit.ID_PRODUIT">
                    <ion-card-content style="">
                      <img :src="`data:image/jpeg;base64,${produit.IMAGE_PRODUITbaze64}`"
                      style="object-fit: cover;width:80px;height:80px;float:left;
                        background-color:#131313;margin-bottom:20px ;margin-right:12px ;
                        border-radius:15px">
                        <span  class="second-text" style="font-size:14px" > <b>{{produit.DESIGNATION_PRODUIT}}</b></span><br>
                        <span  v-if="produit.IN_STOCK > 1">in stock </span><br>
                        <span  v-if="produit.IN_STOCK < 1">out of stock </span>
                        <span class="second-text" style="color:#fff600;font-size:18px" >
                          <b> {{produit.PRIX_VENTE_PRODUIT}}</b></span><br>
                    </ion-card-content>
                </ion-card>
        </ion-content>
      </ion-modal>

</ion-content>
  </ion-page>


</Base-Layout>
</template>

<script>
import {
    IonCard,
    IonButtons,
    IonButton,
    IonModal,
    IonContent,
    IonToolbar,
    IonTitle,
    IonPage,
    IonRow,
    IonCol
} from '@ionic/vue';
  import {
    ipServerObjet as ipServerObjet
} from './Configserver.js';
export default{
    data() {
      return {
        presentingElement: null,
        presentingElement2: null,
        presentingElement3: null,
        presentingElement4: null,
        DataProduitSource: [],
        DataProduitNutritionnel: [],
        DataBoisson: [],
        DataRepas: [],
         ipServer:'',
      };
    },
    async created() {

       for (const property in ipServerObjet) {
    this.ipServer = ipServerObjet[property].ip;
    }
    
    this.axios({
           url: 'http://'+localStorage.getItem('ip')+':1500/api/PRODUITS/1/0',
            method: 'get',
            timeout: 6000,
        }).then(response => {
           this.DataProduitNutritionnel = response.data;
          // this.imgSrc='data:image/jpeg;base64,'+ this.dataSource[0].IMAGE_CLIENT;
        });

        this.axios({

            url: 'http://'+localStorage.getItem('ip')+':1500/api/PRODUITS/2/0',
            method: 'get',
            timeout: 6000,
        }).then(response => {
           this.DataProduitSource = response.data;
          // this.imgSrc='data:image/jpeg;base64,'+ this.dataSource[0].IMAGE_CLIENT;
        });


        this.axios({
            url: 'http://'+localStorage.getItem('ip')+':1500/api/PRODUITS/3/0',
            method: 'get',
            timeout: 6000,
        }).then(response => {
           this.DataBoisson = response.data;
          // this.imgSrc='data:image/jpeg;base64,'+ this.dataSource[0].IMAGE_CLIENT;
        });
        this.axios({
           url: 'http://'+localStorage.getItem('ip')+':1500/api/PRODUITS/4/0',
            method: 'get',
            timeout: 6000,
        }).then(response => {
           this.DataRepas = response.data;
          // this.imgSrc='data:image/jpeg;base64,'+ this.dataSource[0].IMAGE_CLIENT;
        });

    },
    methods: {
      Produit() {
        this.$refs.Produit.$el.dismiss();
      },
      ProduitNutritionnel() {
        this.$refs.ProduitNutritionnel.$el.dismiss();
      },
      Boisson() {
        this.$refs.Boisson.$el.dismiss();
      },
      Repas() {
        this.$refs.Repas.$el.dismiss();
      },
    },mounted() {
      this.presentingElement = this.$refs.page.$el;
      this.presentingElement2 = this.$refs.page.$el;
      this.presentingElement3 = this.$refs.page.$el;
      this.presentingElement4 = this.$refs.page.$el;
    },
 components: {
     IonCard,
    IonButtons,
    IonButton,
    IonModal,
    IonContent,
    IonToolbar,
    IonTitle,
    IonPage,
    IonRow,
    IonCol
 }
}

</script>
<style>

.first-text{
font-weight:600;font-size:12px;
}
.second-text{
color:white;font-weight:600;font-size:15px;
}
.ion-card2{
    border-radius: 30px  !important;
}
</style>