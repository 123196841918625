<template>
<Base-Layout PageTitle="TARIFICATIONS" page-default-back-Link="/accuiel">

    <ion-card style="--ion-background-color: #1d1d1f;" v-for="tarification in dataSource" :key="tarification.DESIGNATION_TYPE_ENTRAINEMENT">
        
        
        <div v-if="tarification.SEXE_TYPE_ENTRAINEMENT=='HOMME'">
            <ion-card-content>
                <ion-card style="background-color: #0894f6;margin:-10px;">
                    <ion-card-content style="padding:10px">
                        <center>
                            <span class="first-text" style="font-size:18px;color:#000000;"><b>{{ tarification.SEXE_TYPE_ENTRAINEMENT }}</b></span><br>
                            <span class="first-text" style="font-size:45px;color:#ffffff;"> <b>{{ tarification.PRIX_U_TYPE_ENTRAINEMENT }} DA</b> </span><br>
                            <span class="first-text" style="font-size:14px;color:#ffffff;"><b>{{ tarification.DESIGNATION_TYPE_ENTRAINEMENT }}</b></span><br>
                        </center>
                    </ion-card-content>
                </ion-card>
                <br>
                <center>
                    <span class="first-text" style="font-size:18px;color:#ffd304;">{{ tarification.NBR_MOIS_ADMIN }} MOIS</span><br>
                    <span class="first-text" style="font-size:18px">{{ tarification.NBR_SEANCE_TYPE_ENTRAINEMENT }} SEANCES</span><br>
                </center>
            </ion-card-content>
        </div>

        <div v-if="tarification.SEXE_TYPE_ENTRAINEMENT=='FEMME'">
            <ion-card-content>
                <ion-card style="background-color: #de047e;margin:-10px;">
                    <ion-card-content style="padding:10px">
                        <center>
                            <span class="first-text" style="font-size:18px;color:#000000;"><b>{{ tarification.SEXE_TYPE_ENTRAINEMENT }}</b></span><br>
                            <span class="first-text" style="font-size:45px;color:#ffffff;"> <b>{{ tarification.PRIX_U_TYPE_ENTRAINEMENT }} DA</b> </span><br>
                            <span class="first-text" style="font-size:14px;color:#ffffff;"><b>{{ tarification.DESIGNATION_TYPE_ENTRAINEMENT }}</b></span><br>
                        </center>
                    </ion-card-content>
                </ion-card>
                <br>
                <center>
                    <span class="first-text" style="font-size:18px;color:#ffd304;">{{ tarification.NBR_MOIS_ADMIN }} MOIS</span><br>
                    <span class="first-text" style="font-size:18px">{{ tarification.NBR_SEANCE_TYPE_ENTRAINEMENT }} SEANCES</span><br>
                </center>
            </ion-card-content>
        </div>

        <div v-if="tarification.SEXE_TYPE_ENTRAINEMENT=='HOMME/FEMME'">
            <ion-card-content>
                <ion-card style="background-color: #bdc921;margin:-10px;">
                    <ion-card-content style="padding:10px">
                        <center>
                            <span class="first-text" style="font-size:18px;color:#000000;"><b>{{ tarification.SEXE_TYPE_ENTRAINEMENT }}</b></span><br>
                            <span class="first-text" style="font-size:45px;color:#ffffff;"> <b>{{ tarification.PRIX_U_TYPE_ENTRAINEMENT }} DA</b> </span><br>
                            <span class="first-text" style="font-size:14px;color:#ffffff;"><b>{{ tarification.DESIGNATION_TYPE_ENTRAINEMENT }}</b></span><br>
                        </center>
                    </ion-card-content>
                </ion-card>
                <br>
                <center>
                    <span class="first-text" style="font-size:18px;color:#ffd304;">{{ tarification.NBR_MOIS_ADMIN }} MOIS</span><br>
                    <span class="first-text" style="font-size:18px">{{ tarification.NBR_SEANCE_TYPE_ENTRAINEMENT }} SEANCES</span><br>
                </center>
            </ion-card-content>
        </div>

        <div v-if="tarification.SEXE_TYPE_ENTRAINEMENT=='KIDS'">
            <ion-card-content>
                <ion-card style="background-color: #07889d;margin:-10px;">
                    <ion-card-content style="padding:10px">
                        <center>
                            <span class="first-text" style="font-size:18px;color:#000000;"><b>{{ tarification.SEXE_TYPE_ENTRAINEMENT }}</b></span><br>
                            <span class="first-text" style="font-size:45px;color:#ffffff;"> <b>{{ tarification.PRIX_U_TYPE_ENTRAINEMENT }} DA</b> </span><br>
                            <span class="first-text" style="font-size:14px;color:#ffffff;"><b>{{ tarification.DESIGNATION_TYPE_ENTRAINEMENT }}</b></span><br>
                        </center>
                    </ion-card-content>
                </ion-card>
                <br>
                <center>
                    <span class="first-text" style="font-size:18px;color:#ffd304;">{{ tarification.NBR_MOIS_ADMIN }} MOIS</span><br>
                    <span class="first-text" style="font-size:18px">{{ tarification.NBR_SEANCE_TYPE_ENTRAINEMENT }} SEANCES</span><br>
                </center>
            </ion-card-content>
        </div>
        

    </ion-card>

</Base-Layout>
</template>

<script>
import {IonCard,IonCardContent} from '@ionic/vue';
import {ipServerObjet as ipServerObjet} from './Configserver.js';
export default {
    components: {
        IonCard,IonCardContent
    },
    data() {
        return {
            dataSource: [],
             ipServer:'',
        };
    },
    async created() {
         for (const property in ipServerObjet) {
    this.ipServer = ipServerObjet[property].ip;
    }
        this.axios({
            url: ''+this.ipServer+'/api/TARIFICATION',
            method: 'get',
            timeout: 6000,
        }).then(response => {
            this.dataSource = response.data;
        });
    },
}
</script>

<style>
.first-text {
    font-weight: 600;
    font-size: 12px;
}

.second-text {
    color: white;
    font-weight: 600;
    font-size: 15px;
}
</style>
