<template>
<Base-Layout PageTitle="REGISTER" page-default-back-Link="/" style="--background-color:white">
    
    <adminRegister-form @get-adminRegister="getadmin"></adminRegister-form>

</Base-Layout>
</template>

<script>
import {toastController } from '@ionic/vue';
import AdminRegisterForm from '../components/adminRegister.vue';
import { ipServerObjet as ipServerObjet } from './Configserver.js';
export default {
    data() {
        return {
            ipServer: '',
        }
    },
    async created() {
/*
        if (localStorage.getItem('carte') == null) {
            this.$router.push('/');
        } else {
            this.$router.push('/accuiel');
        }
*/
    },
    methods: {
        async successful() {
            const toast = await toastController.create({
                message: 'login successful',
                duration: 1500,
                position: 'bottom',
                color: 'success',
                mode: 'ios'
            });
            await toast.present();
        },

        async incorrect() {
            const toast = await toastController.create({
                message: 'username or password incorrect',
                duration: 1500,
                position: 'bottom',
                color: 'danger',
                mode: 'ios'
            });
            await toast.present();
        },
        getadmin(AdminData) {

            for (const property in ipServerObjet) {
                this.ipServer = ipServerObjet[property].ip;
            }


 this.axios({
               url: ''+this.ipServer+'/api/ADDCLIENT_PSW/' + AdminData.user + '/' + AdminData.password,
                method: 'get',
                timeout: 6000,
            }).then(response => {

                if (response.data != '') {
                    this.successful();
                    
                    const carteData2 = {
                carte: response.data[0].ID_RFID_CLIENT
                
            };

                this.$store.dispatch('addcarte',carteData2);
                //this.$store.dispatch('addsall',carteData2);
                this.$router.push('/accuiel');

                } else {
                    this.incorrect();
                }
            });
            


        }
    },

    components: {
        AdminRegisterForm,
    }
}
</script>

<style>
ion-content {

    --ion-background-color: white;
}
</style>
