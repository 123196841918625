<template>
<apexchartBar type="line" :options="chartOptions" :series="formattedData"></apexchartBar>
</template>
<script>
import {
} from '@ionic/vue';
import {
    ipServerObjet as ipServerObjet
} from '../pages/Configserver.js';
import VueApexCharts from "vue3-apexcharts";
export default {
    data() {
        return {
            InbodyData: '',
            courbe1: [],
            courbe: [],
            json:[],
        }
    },
    async created() {
        for (const property in ipServerObjet) {
            this.ipServer = ipServerObjet[property].ip;
        }

       // this.json= JSON.parse(localStorage.getItem('storage1')); // test if not null data
        this.json= this.data; // test if not null
        this.json.forEach((item) => {
               // console.log('', item.WT + '/' + item.DATE_SUIVI_CLIENT, '')

                if (this.type == "Poids (kg)") {
                    this.courbe.push({
                        y: item.WT,
                        x: item.DATE_SUIVI_CLIENT
                    });
                }
                if (this.type == "Muscles (kg)") {
                    this.courbe.push({
                        y: item.SMM,
                        x: item.DATE_SUIVI_CLIENT
                    });
                }
                if (this.type == "Taux de graisse corporelle (%)") {
                    this.courbe.push({
                        y: item.PBF,
                        x: item.DATE_SUIVI_CLIENT
                    });
                }

            })

    },

    computed: {
        chartOptions() {
            return {
                annotations: {},
                colors: ['#ffe400', '#ffe400'],
                chart: {
                    zoom: {
                        enabled: false,
                    },
                    type: 'Line',
                    id: 'Poids (kg)',
                    animations: {
                        enabled: true,
                    },

                    toolbar: {
                        show: false,
                        offsetX: 10,
                        offsetY: 10,
                        autoSelected: 'zoom'
                    },

                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    dashArray: 4,
                    width: 2
                },
                markers: {
                    size: 3,
                    colors: ['#2c2c2c', 'transparent']
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        },
                    },
                    row: {
                        colors: ['#2c2c2c', 'transparent'],
                        opacity: 0.1
                    },
                    column: {
                        colors: ['#2c2c2c', 'transparent'],
                        opacity: 0.9
                    },
                    padding: {
                        right: 5,
                        left: 5
                    }
                },
                tooltip: {
                    x: {
                        format: "dd/MM/yyyy HH:mm",
                    },
                },
                title: {
                    align: 'center',
                    text: this.type
                },
                xaxis: {
                    type: 'datetime',
                    categories: [],
                    labels: {
                        format: 'dd/MM/yyyy HH:mm'
                    }
                },
            };
        },
        formattedData() {
            const stockSerie1 = this.courbe.map(d => {
                return {
                    x: d.x,
                    y: d.y
                };
            });
            return [{
                name: this.type,
                type: 'line',
                data: stockSerie1,

            }];

        }
    },
    components: {
        apexchartBar: VueApexCharts
    },
    props: {
        type: String,
        data: Object,
    }
}
</script>

<style>
.first-text {
    font-weight: 600;
    font-size: 12px;
}

.second-text {
    color: white;
    font-weight: 600;
    font-size: 15px;
}
</style>
