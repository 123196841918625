<template>
<ion-page id="main-content">
    <ion-header>
        <!----background:#070707   :default-href="pageDefaultebackLink"-->
        <ion-toolbar style="--background:#070707">

            <ion-buttons slot="start">
                <ion-back-button :default-href="pageDefaultBackLink"></ion-back-button>
            </ion-buttons>

            <ion-title>
                <span style="color:white">{{PageTitle}}</span>
            </ion-title>

            <ion-buttons slot="end">
                 <img @click="homme()" src="/assets/images/homme.png" style="object-fit: cover;width:30px;height:30px;">

                <img v-if="this.connextion=='aa'"    id="popover-button" src="/assets/images/outside.png" style="object-fit: cover;width:30px;height:30px;">
                <img v-if="this.connextion=='aa'"    id="popover-button" src="/assets/images/inside.png" style="object-fit: cover;width:30px;height:30px;">

                <img src="/assets/images/ellipse.png" style="object-fit: cover;width:30px;height:30px;margin-left:10px">



                <ion-popover trigger="popover-button" :dismiss-on-select="true">
                    <ion-content>
                        <ion-list>
                            <ion-item router-link="/" :button="true" :detail="false">
                                <img src="/assets/images/outside.png"
                                style="object-fit: cover;width:20px;height:20px;color:white"> à l'extérieur de gym</ion-item>
                            <ion-item router-link="/" :button="true" :detail="false">
                                <img src="/assets/images/inside.png"
                                style="object-fit: cover;width:20px;height:20px;color:white"> à l'intérieur de gym</ion-item>
                        </ion-list>
                    </ion-content>
                </ion-popover>



            </ion-buttons>

        </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" mode="ios">
        <slot />
    </ion-content>

</ion-page>
</template>

<script>
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonItem,
    IonList,
    IonPopover
} from '@ionic/vue';
import {
    add,
    ellipsisHorizontal,
    ellipsisVertical
} from 'ionicons/icons';
export default {
  methods: {
      homme(){
          this.$router.push('/');
      }
  },
  data () {
    return {
        connextion:'',
    }
  },

    async created() {
this.connextion=localStorage.getItem('ip');
    },
    setup() {
        return {
            add,
            ellipsisHorizontal,
            ellipsisVertical
        };
    },
    props: ["PageTitle", "pageDefaultBackLink"],
    components: {
        IonButtons,
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar,
        IonBackButton,
        IonItem,
        IonList,
        IonPopover

    }
}
</script>

<style>
.container {
    display: flex;
    overflow-x: auto;

}

ion-card {
    border-radius: 15px;
}

ion-content {
    --ion-background-color: #0e0e0f;
}

.ion-padding2 {
    --ion-background-color: #151516;

}

.fontapp {
    letter-spacing: 0.052em;
    line-height: 1.47059;
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}

/* Let's get this party started */
::-webkit-scrollbar {
    width: 0px;
}
</style>
