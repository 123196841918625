<template>
<Base-Layout PageTitle="BGR GROUPES" page-default-back-Link="/accuiel">

    <ion-card  router-link="/SkillAir" class="ion-button2" style=" width:60px;height:60px;">
        <center>
            <ion-img src="assets/images/qrcode.png" style="object-fit: cover;width:40px;margin-top:10px"> </ion-img>
        </center>
    </ion-card>
    <br>

<ion-card class="ion-button2" style=" width:100%;height:190px;visibility:hidden;position:absolute ">
                <center>
               <Bar  style="background-color:#1C1C1C" id="my-chart-id" :options="chartOptions" :data="chartData" />
                </center>
 </ion-card>
    <br>

    <ion-row v-for="stat in PROGRAMME_BIKE_STAT" :key="stat.DUREE">
        <ion-col size="4">
            <ion-card   class="ion-button2" style=" width:100%;height:115%;">
                <center>
                    <ion-img src="assets/images/icon-duration.png" style="object-fit: cover;width:30px;margin-top:10px"> </ion-img>
                    <span class="first-text" style="font-size:38px;color:white">
                        <span v-if="stat.DUREE==''">0</span>
                        {{stat.DUREE}}</span><br>


                    <span class="first-text" style="font-size:12px">min</span><br>
                </center>
            </ion-card>
        </ion-col>
        <ion-col size="4">
            <ion-card  class="ion-button2" style="width:100%;height:115%;">
                <center>
                    <ion-img src="assets/images/icon-calories.png" style="object-fit: cover;width:30px;margin-top:10px"> </ion-img>
                    <span class="first-text" style="font-size:38px;color:white">
                        <span v-if="stat.KCAL==''">0</span>
                        {{stat.KCAL}}</span><br>
                    <span class="first-text" style="font-size:12px">kcal</span><br>
                </center>
            </ion-card>
        </ion-col>
        <ion-col size="4">
            <ion-card   class="ion-button2" style="width:100%;height:115% ;">
                <center>
                    <ion-img src="assets/images/icon-distance.png" style="object-fit: cover;width:30px;margin-top:10px"> </ion-img>
                    <span class="first-text" style="font-size:38px;color:white">
                        <span v-if="stat.DISTANCE==''">0</span>
                        {{stat.DISTANCE}}</span><br>
                    <span class="first-text" style="font-size:12px">km</span><br>
                </center>
            </ion-card>
        </ion-col>

    </ion-row>
    <br><br>
    <span class="first-text" style="font-size:14px;color:#d2d2d2">SEANCES</span><br>
    <br>
    <ion-card style="--ion-background-color: #1d1d1f;  margin:10px" v-for="stat in PROGRAMME_BIKE" :key="stat.DATE_SUIVI_CLIENT_PROGRAMME_BIKE">
        <ion-card-content style="padding:12px;padding-left:20px">
            <span class="first-text" style="font-size:14px;color:white">{{stat.DESIGNATION_PROGRAMME_BIKE}}</span><br>
            <span class="second-text" style="font-size:12px;color:#fff600">{{stat.DATE_SUIVI_CLIENT_PROGRAMME_BIKE}}</span>
            <ion-row>
                <ion-col>
                    <center>
                        <span class="first-text" style="font-size:14px;color:white">{{stat.DUREE}}</span><br>
                        <span class="first-text" style="font-size:12px">min</span><br>
                    </center>
                </ion-col>
                <ion-col>
                    <center>
                        <span class="first-text" style="font-size:14px;color:white">{{stat.KCAL}}</span><br>
                        <span class="first-text" style="font-size:12px">kcal</span><br>
                    </center>
                </ion-col>
                <ion-col>
                    <center>
                        <span class="first-text" style="font-size:14px;color:white">{{stat.DISTANCE}}</span><br>
                        <span class="first-text" style="font-size:12px">km</span><br>
                    </center>
                </ion-col>
                <ion-col>
                    <center>
                        <span class="first-text" style="font-size:14px;color:white">{{stat.AVG_SPEED}}</span><br>
                        <span class="first-text" style="font-size:12px">AVG-Speed</span><br>
                    </center>
                </ion-col>
            </ion-row>
        </ion-card-content>
    </ion-card>

</Base-Layout>
</template>

<script>
import {
    IonCard,
    IonCol,
    IonRow,
    IonImg,
    IonCardContent

} from '@ionic/vue';
import {
    Bar
} from 'vue-chartjs'



import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'
 import {
    ipServerObjet as ipServerObjet
} from './Configserver.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
var array1 = [];
export default {
  methods: {


  },
    data() {
        return {
            ipServer:'',
            PROGRAMME_BIKE: [],
            PROGRAMME_BIKE_STAT: [],
            datechart:[],
            datachart:[],
            t1:[],
            t2:[],
            chartData: { labels:   this.labelss , datasets: [{ backgroundColor: '#fff600', data: [] }] },
            chartOptions: {
                responsive: true
            }
        };

    },
    async created() {
 for (const property in ipServerObjet) {
    this.ipServer = ipServerObjet[property].ip;
    }

        this.axios({
            url: 'http://'+localStorage.getItem('ip')+':1500/api/PROGRAMME_BIKE/0/' + localStorage.getItem('carte'),
            method: 'get',
            timeout: 6000,
        }).then(response => {
            this.PROGRAMME_BIKE = response.data;
            this.PROGRAMME_BIKE.forEach((item) => {
   this.datechart.push(item.DATE_SUIVI_CLIENT_PROGRAMME_BIKE);
this.datachart.push(item.POWER);
//array1.push(item.POWER);
})

    const  stockSerie1 = this.datachart.map(d => {
                return d;
            });
            console.log(stockSerie1);
            array1=stockSerie1;
        });


this.chartData.labels=this.datechart;
this.t1=this.datachart;


//this.chartData.datasets[0].data[0]=array1[0];
this.chartData.datasets[0].data=array1;

        this.axios({
            url: 'http://'+localStorage.getItem('ip')+':1500/api/PROGRAMME_BIKE_STAT/0/' + localStorage.getItem('carte'),
            method: 'get',
            timeout: 6000,
        }).then(response => {
            this.PROGRAMME_BIKE_STAT = response.data;
        });



    },
    components: {
        IonCard,
        IonCol,
        IonRow,
        Bar,IonImg,IonCardContent
    }
}
</script>

<style>
.first-text {
    font-weight: 600;
    font-size: 12px;
}

.second-text {
    color: white;
    font-weight: 600;
    font-size: 15px;
}
</style>
