import { createStore } from 'vuex';
const store = createStore({
    state() {
        return {
            memories: [
                { carte: '1234567890', },

            ]
        };
    },

    mutations: {
        addcarte(state, carteData) {
            const newCarte = {
                id: carteData.id,
                carte: carteData.carte
            };
            state.memories.unshift(newCarte);
        }
    },
    actions: {
        addcarte(context, carteData) {
            //context.commit('addcarte', memoryData)
            localStorage.setItem('carte', carteData.carte);
        },
        addsall(context, carteData) {
            localStorage.setItem('nsalle', carteData.gymid);
        },
        addip(context, ip) {
            localStorage.setItem('ip', ip);
        },
        addstorage1(context, storage) {
            localStorage.setItem('storage1', storage);
        },
        addstorage2(context, storage) {
            localStorage.setItem('storage2', storage);
        }
    },

    getters: {
        memories(state) {
            return state.memories;
        }
    }

});

export default store;