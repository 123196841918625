<template>
<Base-Layout   PageTitle="Configuration"  page-default-back-Link="/" style="--background-color:white"  >
<center><h2>Configuration</h2></center>


<create-carte-form @save-carte="savememory"></create-carte-form>
</Base-Layout>
</template>
<script>

import   CreateCarteForm  from '../components/createcarte.vue'

import {
 toastController
} from '@ionic/vue';
 import { alertCircleOutline  } from 'ionicons/icons';
export default {
  data () {
    return {
      carte:'',
      EstLocal:0,
      ip:'',
    }
  },
async created() {
/*
 if(localStorage.getItem('carte')==null){
 this.$router.push('/');
  }else{
 this.$router.push('/accuiel');
  }*/

    },
  methods: {
async incorrect(msg) {
            const toast = await toastController.create({
                message:msg,
                duration: 4000,
                position: 'bottom',
                color: 'dark',
                mode: 'ios',
                icon:alertCircleOutline,
                layout:'stacked',
                class:'custom-toast'
            });
            await toast.present();
        },
savememory(carteData){

if(carteData.carte!='' && carteData.gymid!=''){


 //*********** AUTHENTIFICATION EN LIGNE
if(this.EstLocal == 0)
{
  this.axios({
                url: 'https://gym.bgrsmart.com/bgrsmart/get_ip_public.php?N_SALLE=' + carteData.gymid,
                method: 'get',
                timeout: 8000,
            }).then(response => {
                this.data = response.data.data;
                this.data.forEach((item) => {
                  this.ip = item.IP_PUBLIC;
                })

    if (this.ip != '') { // CAS IOS WEB ZEDTHA PCQ FI WEB LZM IDENTIFICATION  ONLIGNE
        this.axios({
                //url: 'http://'+this.ip+':1500/api/CHECK_CARTE/0/'+carteData.carte,
                url: 'https://bgrsmart.com/mafitness/api/CLIENT_PSW_EXIST/amine/12345',
                method: 'get',
                timeout: 8000,
            }).then(response => {
              this.EstLocal = 1;
                this.carte = response.data;
                if(this.carte==''){
                  this.incorrect("Cette carte n'existe pas");
                    }else{
                        this.$store.dispatch('addcarte',carteData);
                        this.$store.dispatch('addsall',carteData);
                        this.$store.dispatch('addip', this.ip);
                        this.$router.push('/accuiel');
                    }
        });
                    
                   
                } else {
                    // this.$router.push('/LoginAdmin');
                    this.incorrect("Veuillez vous rendre au guichet de la salle de sport afin d'obtenir un code d'activation");
                }              
            });


}







}else{
  this.incorrect("S'il-vous-plaît complétez tous les champs");
}

//this.$router.go(this.$router.currentRoute)
}
  },

components:{
    CreateCarteForm
}
}
</script>
<style >
ion-content {

    --ion-background-color:white ;
}
</style>