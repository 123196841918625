<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import {defineComponent } from 'vue';
// storage
//import { Plugins } from "@capacitor/core";
//import "@capacitor-community/sqlite";
//import { SQLiteConnection } from "@capacitor-community/sqlite";
//import { SQLiteConnection } from '@capacitor-community/sqlite';
//const {CapacitorSQLite} = Plugins;

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
/*
setup(){
const database = ref<any>(null);

const initDbTable =async ()=>{

try {

  const CREATE_TABLE=
  "CREATE TABLE IF NOT EXISTS carte ("+
  "id INTEGER PRIMARY KEY NOT NULL,"+
  "carte TEXT NOT NULL);";
 await database.value?.run(CREATE_TABLE);
} catch (e) {
  alert("error initializing th database table")
}

}

  //init database
onBeforeMount( async () =>{
try{

const sqlite =new SQLiteConnection(CapacitorSQLite);

const db =await sqlite.createConnection(
  "ionic-vue-db",
  false,
  "no-encryption",
  1
);
alert("error 1")
await db?.open();
 await initDbTable();
console.log("database opened", db);
database.value=db;
}catch (e){
alert("error initializing th database")
}

});
return {};
}
*/
});
</script>