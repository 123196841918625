<template>
<Base-Layout page-default-back-Link="/accuiel">

    <ion-card style="--ion-background-color: #1d1d1f;margin:-5px">
        <ion-card-content style="padding:12px;padding-left:20px">
            <span class="second-text">SAMEDI </span>
        </ion-card-content>
    </ion-card><br>
    <ion-row>
        <div v-for="cours in dataSource" :key="cours.DESIGNATION_COURS_COLLECTIF">
            <ion-card v-if="cours.JOUR_COURS_COLLECTIF=='samedi'" class="ion-button2" style=" width:100px;height:180px;float:left;margin:2px;margin-bottom:10px">
                <center>
                <!--
                    <ion-img v-if="cours.IMAGE_COURS_COLLECTIFbaze64==''" src="assets/images/noimage2.png" style="object-fit: cover;height:100px"> </ion-img>
                    <img v-if="cours.IMAGE_COURS_COLLECTIFbaze64!=''" :src="`data:image/jpeg;base64,${cour.IMAGE_COURS_COLLECTIFbaze64}`" style="object-fit: cover;width:100px">
                -->    
                    <div>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.DESIGNATION_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.HEURE_D_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.HEURE_F_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:#fff600">{{ cours.COACH_COURS_COLLECTIF }}</span><br>
                    </div>
                </center>
            </ion-card>
        </div>
    </ion-row>
    <br>
    <ion-card style="--ion-background-color: #1d1d1f;margin:-5px">
        <ion-card-content style="padding:12px;padding-left:20px">
            <span class="second-text">DIMANCHE </span>
        </ion-card-content>
    </ion-card><br>
    <ion-row>
        <div v-for="cours in dataSource" :key="cours.DESIGNATION_COURS_COLLECTIF">
            <ion-card v-if="cours.JOUR_COURS_COLLECTIF=='dimanche'" class="ion-button2" style=" width:100px;height:180px;float:left;margin:2px;margin-bottom:10px">
                <center>
                    <ion-img v-if="cours.IMAGE_COURS_COLLECTIFbaze64==''" src="assets/images/noimage2.png" style="object-fit: cover;height:100px"> </ion-img>
                    <img v-if="cours.IMAGE_COURS_COLLECTIFbaze64!=''" :src="`data:image/jpeg;base64,${cour.IMAGE_COURS_COLLECTIFbaze64}`" style="object-fit: cover;width:100px">
                    <div>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.DESIGNATION_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.HEURE_D_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.HEURE_F_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:#fff600">{{ cours.COACH_COURS_COLLECTIF }}</span><br>
                    </div>
                </center>
            </ion-card>
        </div>
    </ion-row>
    <br>

    <ion-card style="--ion-background-color: #1d1d1f;margin:-5px">
        <ion-card-content style="padding:12px;padding-left:20px">
            <span class="second-text">LUNDI </span>
        </ion-card-content>
    </ion-card><br>
    <ion-row>
        <div v-for="cours in dataSource" :key="cours.DESIGNATION_COURS_COLLECTIF">
            <ion-card v-if="cours.JOUR_COURS_COLLECTIF=='lundi'" class="ion-button2" style=" width:100px;height:180px;float:left;margin:2px;margin-bottom:10px">
                <center>
                    <ion-img v-if="cours.IMAGE_COURS_COLLECTIFbaze64==''" src="assets/images/noimage2.png" style="object-fit: cover;height:100px"> </ion-img>
                    <img v-if="cours.IMAGE_COURS_COLLECTIFbaze64!=''" :src="`data:image/jpeg;base64,${cour.IMAGE_COURS_COLLECTIFbaze64}`" style="object-fit: cover;width:100px">
                    <div>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.DESIGNATION_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.HEURE_D_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.HEURE_F_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:#fff600">{{ cours.COACH_COURS_COLLECTIF }}</span><br>
                    </div>
                </center>
            </ion-card>
        </div>
    </ion-row>
    <br>
    <ion-card style="--ion-background-color: #1d1d1f;margin:-5px">
        <ion-card-content style="padding:12px;padding-left:20px">
            <span class="second-text">MARDI </span>
        </ion-card-content>
    </ion-card><br>
    <ion-row>
        <div v-for="cours in dataSource" :key="cours.DESIGNATION_COURS_COLLECTIF">
            <ion-card v-if="cours.JOUR_COURS_COLLECTIF=='mardi'" class="ion-button2" style=" width:100px;height:180px;float:left;margin:2px;margin-bottom:10px">
                <center>
                    <ion-img v-if="cours.IMAGE_COURS_COLLECTIFbaze64==''" src="assets/images/noimage2.png" style="object-fit: cover;height:100px"> </ion-img>
                    <img v-if="cours.IMAGE_COURS_COLLECTIFbaze64!=''" :src="`data:image/jpeg;base64,${cour.IMAGE_COURS_COLLECTIFbaze64}`" style="object-fit: cover;width:100px">
                    <div>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.DESIGNATION_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.HEURE_D_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.HEURE_F_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:#fff600">{{ cours.COACH_COURS_COLLECTIF }}</span><br>
                    </div>
                </center>
            </ion-card>
        </div>
    </ion-row>
    <br>
    <ion-card style="--ion-background-color: #1d1d1f;margin:-5px">
        <ion-card-content style="padding:12px;padding-left:20px">
            <span class="second-text">MERCREDI </span>
        </ion-card-content>
    </ion-card><br>
    <ion-row>
        <div v-for="cours in dataSource" :key="cours.DESIGNATION_COURS_COLLECTIF">
            <ion-card v-if="cours.JOUR_COURS_COLLECTIF=='mercredi'" class="ion-button2" style=" width:100px;height:180px;float:left;margin:2px;margin-bottom:10px">
                <center>
                    <ion-img v-if="cours.IMAGE_COURS_COLLECTIFbaze64==''" src="assets/images/noimage2.png" style="object-fit: cover;height:100px"> </ion-img>
                    <img v-if="cours.IMAGE_COURS_COLLECTIFbaze64!=''" :src="`data:image/jpeg;base64,${cour.IMAGE_COURS_COLLECTIFbaze64}`" style="object-fit: cover;width:100px">
                    <div>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.DESIGNATION_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.HEURE_D_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.HEURE_F_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:#fff600">{{ cours.COACH_COURS_COLLECTIF }}</span><br>
                    </div>
                </center>
            </ion-card>
        </div>
    </ion-row>
    <br>
    <ion-card style="--ion-background-color: #1d1d1f;margin:-5px">
        <ion-card-content style="padding:12px;padding-left:20px">
            <span class="second-text">JEUDI </span>
        </ion-card-content>
    </ion-card><br>
    <ion-row>
        <div v-for="cours in dataSource" :key="cours.DESIGNATION_COURS_COLLECTIF">
            <ion-card v-if="cours.JOUR_COURS_COLLECTIF=='jeudi'" class="ion-button2" style=" width:100px;height:180px;float:left;margin:2px;margin-bottom:10px">
                <center>
                 <!--  <ion-img v-if="cours.IMAGE_COURS_COLLECTIFbaze64==''" src="assets/images/noimage2.png" style="object-fit: cover;height:100px"> </ion-img>
                    <img v-if="cours.IMAGE_COURS_COLLECTIFbaze64!=''" :src="`data:image/jpeg;base64,${cour.IMAGE_COURS_COLLECTIFbaze64}`" style="object-fit: cover;width:100px">
                   --> 
                   
                    <div>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.DESIGNATION_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.HEURE_D_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.HEURE_F_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:#fff600">{{ cours.COACH_COURS_COLLECTIF }}</span><br>
                    </div>
                </center>
            </ion-card>
        </div>
    </ion-row>
    <br>
    <ion-card style="--ion-background-color: #1d1d1f;margin:-5px">
        <ion-card-content style="padding:12px;padding-left:20px">
            <span class="second-text">VENDREDI </span>
        </ion-card-content>
    </ion-card><br>
    <ion-row>
        <div v-for="cours in dataSource" :key="cours.DESIGNATION_COURS_COLLECTIF">
            <ion-card v-if="cours.JOUR_COURS_COLLECTIF=='vendredi'" class="ion-button2" style=" width:100px;height:180px;float:left;margin:2px;margin-bottom:10px">
                <center>
                    <ion-img v-if="cours.IMAGE_COURS_COLLECTIFbaze64==''" src="assets/images/noimage2.png" style="object-fit: cover;height:100px"> </ion-img>
                    <img v-if="cours.IMAGE_COURS_COLLECTIFbaze64!=''" :src="`data:image/jpeg;base64,${cour.IMAGE_COURS_COLLECTIFbaze64}`" style="object-fit: cover;width:100px">
                    <div>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.DESIGNATION_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.HEURE_D_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:white">{{ cours.HEURE_F_COURS_COLLECTIF }}</span><br>
                        <span class="first-text" style="font-size:13px;color:#fff600">{{ cours.COACH_COURS_COLLECTIF }}</span><br>
                    </div>
                </center>
            </ion-card>
        </div>
    </ion-row>
    <br>
</Base-Layout>
</template>

<script>
import {
    IonCard,
    IonRow
} from '@ionic/vue';
import {
    ipServerObjet as ipServerObjet
} from './Configserver.js';

export default {
    components: {
        IonCard,
        IonRow
    },
    data() {
        return {
            dataSource: [],
        };
    },
    async created() {
        for (const property in ipServerObjet) {
            this.ipServer = ipServerObjet[property].ip;
        }

        this.axios({

             url: 'http://'+localStorage.getItem('ip')+':1500/api/GET_COURS_COLLECTIF/0/0',
            method: 'get',
            timeout: 6000,
        }).then(response => {
            this.dataSource = response.data;
        });
    },
}
</script>

<style>
.first-text {
    font-weight: 600;
    font-size: 12px;
}

.second-text {
    color: white;
    font-weight: 600;
    font-size: 15px;
}
</style>
