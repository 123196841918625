<template>
<Base-Layout PageTitle="BGR SMART GYM" page-default-back-Link="/">

    <ion-fab slot="fixed" vertical="top" horizontal="end" :edge="true" id="about" expand="block">
        <ion-fab-button>
            <center>
                <img src="assets/images/icons/about.png" alt="">
            </center>
        </ion-fab-button>
    </ion-fab>

    <ion-page class="main-content">

        <h2 v-if="datastoreg==null" style="color:white">no data  </h2>

        <ion-content class="ion-padding">
            <div class="container">
                <div>
                    <ion-card router-link="/Groupes" itemprop="" style=" background: url(assets/images/groupsycle.png);
                        background-repeat: no-repeat; background-size: cover;margin:5px;width:300px;height:180px;">

                        <ion-card style=" background: url(assets/images/pluse.png);
             background-repeat: no-repeat; background-size: cover;margin:15px;margin-top:120px;width:100px;height:45px;">

                        </ion-card>
                    </ion-card>
                </div>



            </div>


            <ion-grid style="padding:0px">
                <ion-row>
                    <ion-col size="12">
                        <ion-card router-link="/WebView" style=" background: url(assets/images/inbody.png);
                        background-repeat: no-repeat; background-size: cover;margin:5px;width:99%;height:100px;">
                        </ion-card>
                        
                    </ion-col>
                </ion-row>



                <ion-row>
                    <ion-col size="4">
                        <ion-card router-link="/profil" class="ion-button2" style="width:100%;height:95%;">
                            <ion-img src="assets/images/icons/profile.png" style="object-fit: cover;margin:20%"> </ion-img>
                            <div class="first-text" style="margin-top: -30px; padding-bottom: 20px; font-size:10px;">PROFILE</div>
                        </ion-card>
                        
                    </ion-col>

                    <ion-col size="4">
                        <ion-card router-link="/Abonnement" class="ion-button2" style="width:100%;height:95%;">
                            <ion-img src="assets/images/icons/abonnement.png" style="object-fit: cover;margin:20%"> </ion-img>
                            <div class="first-text" style="margin-top: -30px; padding-bottom: 20px; font-size:10px;">ABONNEMENT</div>
                        </ion-card>
                       
                    </ion-col>

                    
                    <ion-col size="4">
                        <ion-card router-link="/ControlAcces" class="ion-button2" style="width:100%;height:95%;">
                            <ion-img src="assets/images/icons/turnstile.png" style="object-fit: cover;margin:20%"> </ion-img>
                            <div class="first-text" style="margin-top: -30px; padding-bottom: 20px; font-size:10px;">CONTROLE D'ACCES</div>
                        </ion-card>
                    </ion-col>
                </ion-row>



                <ion-row>
                    <ion-col size="4">
                        <ion-card router-link="/CahierDentrainement" class="ion-button2" itemprop="" style="width:100%;height:95%;">
                            <ion-img src="assets/images/icons/cahierentrainement.png" style="object-fit: cover;margin:20%"> </ion-img>
                            <div class="first-text" style="margin-top: -30px; padding-bottom: 20px; font-size:10px;">CAHIER<br> D'ENTRAINEMENT</div>
                        </ion-card>
                    </ion-col>
                    
                    <ion-col size="4">
                        <ion-card router-link="/SuivieAthlete" class="ion-button2" itemprop="" style="width:100%;height:95%;">
                            <ion-img src="assets/images/icons/suiviAthlete.png" style="object-fit: cover;margin:20%"> </ion-img>
                            <div class="first-text" style="margin-top: -30px; padding-bottom: 20px; font-size:10px;">SUIVI <br>ATHLETE</div>
                        </ion-card>
                    </ion-col>
                    
                    <!--
 

                    <ion-col size="4">
                        <ion-card router-link="/Inbody" class="ion-button2" itemprop="" style="width:100%;height:95%;">
                            <ion-img src="assets/images/icons/suiviINBODY.png" style="object-fit: cover;margin:20%"> </ion-img>
                            <div class="first-text" style="margin-top: -30px; padding-bottom: 20px; font-size:10px;">SUIVI <br>INBODY</div>
                        </ion-card>
                    </ion-col>
                </ion-row>



                <ion-row>
 -->                   
                    <ion-col size="4">
                        <ion-card router-link="/WebView" class="ion-button2" itemprop="" style="width:100%;height:95%;">
                            <ion-img src="assets/images/icons/boutique.png" style="object-fit: cover;margin:20%"> </ion-img>
                            <div class="first-text" style="margin-top: -30px; padding-bottom: 20px; font-size:10px;">BOUTIQUE</div>
                        </ion-card>
                    </ion-col>


                    <ion-col size="4">
                        <ion-card router-link="/Tarification" class="ion-button2" itemprop="" style="width:100%;height:95%;">
                            <ion-img src="assets/images/icons/tarification.png" style="object-fit: cover;margin:20%"> </ion-img>
                            <div class="first-text" style="margin-top: -30px; padding-bottom: 20px; font-size:10px;">TARIFICATIONS</div>
                        </ion-card>
                    </ion-col>




                </ion-row>


            </ion-grid>

            <ion-modal ref="about" trigger="about" :initial-breakpoint="0.5" :breakpoints="[0, 0.25, 0.5, 0.75]" style=" background-color:rgba(40, 40, 40, 0.5);">
                <ion-content class="ion-padding">
                    <div class="ion-margin-top">
                        <center>
                            <img src="assets/images/icons/logo.png" alt="" style="width:320px">

                        </center>
                        <ion-card style="--ion-background-color: #1d1d1f;" id="historique" expand="block">
                            <ion-card-content>
                                <ion-img src="assets/images/icons/phone.png" style="object-fit: cover;margin-top:-3px;width:25px;float:left"> </ion-img>
                                <span class="second-text" style="font-size:14px;color:#f6f6f6;margin-left:10px"> 0770 90 52 59 / 0778 04 76 86 </span>
                            </ion-card-content>
                        </ion-card>
                        <ion-card style="--ion-background-color: #1d1d1f;" id="historique" expand="block">
                            <ion-card-content>
                                <ion-img src="assets/images/icons/fb.png" style="object-fit: cover;margin-top:-3px;width:25px;float:left"> </ion-img>
                                <span class="second-text" style="font-size:14px;color:#f6f6f6;margin-left:10px"> BGR SMART solution </span>
                            </ion-card-content>
                        </ion-card>
                        <ion-card style="--ion-background-color: #1d1d1f;" id="historique" expand="block">
                            <ion-card-content>
                                <ion-img src="assets/images/icons/insta.png" style="object-fit: cover;margin-top:-3px;width:25px;float:left"> </ion-img>
                                <span class="second-text" style="font-size:14px;color:#f6f6f6;margin-left:10px"> bgr_smart_solution </span>
                            </ion-card-content>
                        </ion-card>

                        <ion-card style="--ion-background-color: #1d1d1f;" id="historique" expand="block">
                            <ion-card-content>
                                <ion-img src="assets/images/icons/v.png" style="object-fit: cover;margin-top:-3px;width:25px;float:left"> </ion-img>

                                <span class="second-text" style="font-size:14px;color:#f6f6f6;margin-left:10px">Version 1.0.1 </span>
                            </ion-card-content>
                        </ion-card>

                    </div>
                </ion-content>
            </ion-modal>
        </ion-content>
    </ion-page>
</Base-Layout>
</template>

<script>
import {

    IonContent,
    IonPage,
    IonCard,
    IonCol,
    IonGrid,
    IonRow,
    IonImg,
    IonFab,
    IonFabButton,
    IonModal,
    useBackButton,
    useIonRouter
} from '@ionic/vue';
import {
    App
} from '@capacitor/app';
export default {
    data() {
        return {
            datastoreg: '',
        }
    },
    components: {
        IonContent,
        IonPage,
        IonCard,
        IonCol,
        IonGrid,
        IonRow,
        IonImg,
        IonFab,
        IonFabButton,
        IonModal,
    },
    async created() {
        this.datastoreg = localStorage.getItem('carte');

        if (localStorage.getItem('carte') == null) {
            this.$router.push('/');
        } else {
            this.$router.push('/accuiel');
        }
    },
    computed: {
        memories() {
            return this.$store.getters.memories;
        }
    },
    setup() {
        const ionRouter = useIonRouter();
        useBackButton(-1, () => {
            if (!ionRouter.canGoBack()) {
                App.exitApp();
            }
        });
    }
}
</script>

<style>
ion-fab-button {
    margin-top: -15px;
    width: 42px;
    height: 42px;
    --background: #070707;
    --background-activated: #fff600;
    --background-hover: #fff600;
    --border-radius: 15px;
    --box-shadow: 0px 1px 2px 0px rgb(0, 0, 0), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    --color: black;
}

.container {
    display: flex;
    overflow-x: auto;

}

ion-card {
    border-radius: 15px;
}

ion-content {}

.ion-padding2 {
    --ion-background-color: #2D2D30;

}

.ion-button1 {
    --background-color: #e7be00;
    --opacity: 0.8;
    height: 40px;
    color: #2D2D30;
    font-weight: 700;
    font-size: 15px;
    padding: 0px;
    --border-radius: 10px !important;
}

.ion-button2 {
    --background: #1C1C1C;
    --opacity: 0.8;
    height: 30px;
    padding: 0px;
    margin: 0px;
    --border-radius: 10px !important;
}

/* Let's get this party started */
::-webkit-scrollbar {
    width: 0px;
}

.first-text {
    text-align: center; 
    color:white; 
}


</style>
