<template>
   
<Base-Layout PageTitle="SHOP" page-default-back-Link="/" style="--background-color:white">


    <ion-content>
    <iframe :src= this.sitewebLink  style="width:100%;height:100%" scrolling="yes" ></iframe>
</ion-content>

</Base-Layout>
</template>

<script>

import {ipServerObjet as ipServerObjet} from './Configserver.js';
export default {
    data() {
        return {
            ipServer:'',
            sitewebLink:'',
        }
    },
    async created() {
        for (const property in ipServerObjet) {
            this.ipServer = ipServerObjet[property].ip;
            this.sitewebLink = ipServerObjet[property].sitewebLink;
        }
    },
    methods: {


    },

    components: {
        
    }
}
</script>

<style>
ion-content {

    --ion-background-color: white;
}
</style>
