<template>
<Base-Layout PageTitle="LOCATION ARMOIRE" page-default-back-Link="/accuiel">
    <ion-page>
        <ion-content>
            <center>
                <img src="assets/images/nodata.png" style="border-radius:15px;  object-fit: cover;margin:-2px;width:250px" />
            </center>

            <div v-if="this.armoir">

    <ion-card style="--ion-background-color: #1d1d1f;" id="historique" expand="block">
        <ion-card-content>
            <ion-img src="assets/images/icons/historique.png" style="object-fit: cover;margin-top:-5px;width:30px;float:left"> </ion-img>
            <span class="second-text" style="font-size:14px;color:#f6f6f6">HISTORIQUE LOCATION</span>
        </ion-card-content>
    </ion-card>
    <span class="first-text" style="font-size:14px;color:#d2d2d2;padding:5px"> LOCATION ARMOIRE</span><br>
    <div>
        <ion-card style="--ion-background-color: #1d1d1f;">
            <ion-card-content>
                <ion-row style=" ;margin:-10px">
                    <ion-col size="10">
                        <span class="second-text">element-1</span> <br>
                        <span class="second-text" style="color:#696969"> <b>DU :2022-01-30</b> <b> AU :2022-02-04</b> </span>
                    </ion-col>
                    <ion-col size="2">
                        <br>
                        <img src="assets/images/icons/calander.png" style="   object-fit: cover;margin:-2px;width:20px" />
                        <span class="second-text" style="margin-left:5px;color:#f8ec04">5</span>
                    </ion-col>
                </ion-row>
            </ion-card-content>
        </ion-card>
    </div>

    <!--historique-->
    <ion-modal ref="historique" trigger="historique" :presenting-element="presentingElement">
        <ion-header>
            <ion-toolbar>
                <ion-title>HISTORIQUE LOCATION ARMOIRE</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="historique()">Close</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content>

            <!--Item HISTORIQUE LOCATION ARMOIRE-->
            <ion-card   style="--ion-background-color: #1d1d1f;">
            <ion-card-content  >
                <ion-row style=" ;margin:-10px">
                    <ion-col size="10">
                     <span class="second-text">element-1</span> <br>
                     <span class="second-text" style="color:#696969" > <b>DU :2022-01-30</b> <b> AU :2022-02-04</b>  </span>
                    </ion-col>
                    <ion-col size="2">
                      <br>
                      <img src="assets/images/icons/calander.png"
                              style="   object-fit: cover;margin:-2px;width:20px"/>
                     <span class="second-text" style="margin-left:5px;color:#f8ec04">5</span>
                    </ion-col>
                </ion-row>
            </ion-card-content>
        </ion-card>
            <!--Item HISTORIQUE LOCATION ARMOIRE-->
        </ion-content>
    </ion-modal>
            </div>

        </ion-content>
    </ion-page>


</Base-Layout>
</template>

<script>
import {
    IonButtons,
    IonButton,
    IonModal,
    IonContent,
    IonPage,
} from '@ionic/vue';
import {
    ellipse,
    square,
    triangle
} from 'ionicons/icons';

export default {
    methods: {
        historique() {
            this.$refs.historique.$el.dismiss();
        },
    },
    name: 'Tabs',
    components: {
        IonContent,
        IonPage,
        IonButtons,
        IonButton,
        IonModal,

    },  data() {
        return {
            armoir: '',
        };
    },
    setup() {
        return {
            ellipse,
            square,
            triangle,
        };
    },
};
</script>
