<template>
<Base-Layout PageTitle="ABONNEMENTS" page-default-back-Link="/accuiel">

 <center>
<ion-spinner v-if="ProgressBar" style="margin-top:150px;transform: scale(2);" name="bubbles"></ion-spinner>
</center>

   <div v-if="this.Abonnement==''" style="color:white">
        <center>
            <img src="assets/images/nodata2.png" style=" object-fit: cover;margin-top:150px;width:350px" />
        </center>
        <ion-card router-link="/accuiel" style="--ion-background-color: #1d1d1f;  margin:10px">
            <ion-card-content style="padding:12px;padding-left:20px">
                <center>
                    Accueil
                </center>
            </ion-card-content>
        </ion-card>
    </div>



    <br>
    <!--Item Abonnement-->
    <div v-for="Abonnement in dataSource" :key="Abonnement.ID_ENTRAINEMENT"  >
        <ion-card style=" background-color:#151516; background-repeat: no-repeat; background-size: cover;margin:0px; ">
            <ion-card-content>
                <span class="first-text">TYPE ABONNEMENT</span><br>
                <span class="second-text">{{Abonnement.TYPE_ENTRAINEMENT}}</span>
                <hr>
                <span class="first-text">NBR MOIS</span><br>
                <span class="second-text">{{Abonnement.NBR_MOIS}}</span>
                <hr>
                <span class="first-text">PRIX</span><br>
                <span class="second-text">{{Abonnement.PRIX}}</span>
                <hr>

                <span class="first-text">DATE DEMMARAGE</span><br>
                <span class="second-text">{{Abonnement.DATE_DEMMARAGE_ENTRAINEMENT}}</span>
                <hr>
                <span class="first-text">DATE FIN</span><br>
                <span class="second-text">{{Abonnement.DATE_FIN_ENTRAINEMENT}}</span>
                <hr>
                <span class="first-text">SEANCES PREVUES</span><br>
                <span class="second-text">{{Abonnement.NBR_SEANCE}}</span>
                <hr>
                <span class="first-text">SEANCES RESTES</span><br>
                <span class="second-text" style="color:#fc2332">{{Abonnement.NBR_SEANCE_RESTE}}</span>
                <hr>
                <div v-if="Abonnement.EST_COURS_COLLECTIF==0">
                    <span class="first-text">JOURS AUTHORISES</span><br>
                    <span class="second-text" style="color:#fff600">{{Abonnement.JOURS_ENTRAINEMENT}}</span>
                </div>
                <div v-if="Abonnement.EST_COURS_COLLECTIF==1"  >
                    <span class="first-text">SCIENCES AUTHORISES</span><br>

                    <ion-card v-for="cour in Abonnement.COURS_COLLECTIF" :key="cour.DESIGNATION_COURS_COLLECTIF" class="ion-button2" style=" width:100px;height:180px;float:left;margin:2px;margin-bottom:10px">
                        <center>
                            <ion-img v-if="cour.IMAGE_COURS_COLLECTIFbaze64==''" src="assets/images/noimage2.png" style="object-fit: cover;height:85px"> </ion-img>
                           <img  v-if="cour.IMAGE_COURS_COLLECTIFbaze64!=''" :src="`data:image/jpeg;base64,${cour.IMAGE_COURS_COLLECTIFbaze64}`" style="object-fit: cover;width:100px">

                            <div style="margin-top:-20px">
                                <span class="first-text" style="font-size:12px;color:black;background-color:#fff600;padding:2px">{{cour.JOUR_COURS_COLLECTIF}}</span><br>
                                <span class="first-text" style="font-size:13px;color:white">{{ cour.DESIGNATION_COURS_COLLECTIF }}</span><br>
                                <span class="first-text" style="font-size:13px;color:white">{{cour.HEURE_D_COURS_COLLECTIF}}</span><br>
                                <span class="first-text" style="font-size:13px;color:white">{{cour.HEURE_F_COURS_COLLECTIF}}</span><br>
                            </div>
                        </center>

                    </ion-card>

                </div>

            </ion-card-content>
        </ion-card>
<br>
    </div>

</Base-Layout>
</template>

<script>
import {
    IonCard,
    IonCardContent,
      IonSpinner
} from '@ionic/vue';
 import {
    ipServerObjet as ipServerObjet
} from './Configserver.js';

export default {
    data() {
        return {
            dataSource: [],
            imgSrc: '',
            ProgressBar: false,
        };
    },
    async created() {
        if(localStorage.getItem('carte')==null){
this.$router.push('/');
  }
    for (const property in ipServerObjet) {
    this.ipServer = ipServerObjet[property].ip;
    }
        this.ProgressBar = true;
        this.axios({
            //url: 'http://'+localStorage.getItem('ip')+':1500/api/ABONNEMENT/0/' + localStorage.getItem('carte'),
            url: ''+this.ipServer+'/api/ABONNEMENT_CLIENT/' + localStorage.getItem('carte'),
            method: 'get',
            timeout: 6000,
        }).then(response => {
            this.dataSource = response.data;
            if (response.data != '') {
                this.ProgressBar = false;
            }
        });
        this.ProgressBar = false;
    },
    components: {
        IonCard,
        IonCardContent,
        IonSpinner
    }
}
</script>

<style>
.first-text {
    font-weight: 600;
    font-size: 12px;
}

.second-text {
    color: white;
    font-weight: 600;
    font-size: 15px;
}
</style>
