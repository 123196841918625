import { createRouter, createWebHistory } from '@ionic/vue-router';
//import MemoriesPage from '../pages/MemoriesPage.vue'
import AccuielPage from '../pages/Accueil.vue'
import ProfilPage from '../pages/Profil.vue'
import AbonnementPage from '../pages/Abonnement.vue'
import CahierDentrainementPage from '../pages/CahierDentrainement.vue'
import SuivieAthletePage from '../pages/SuivieAthlete.vue'
import CartePage from '../pages/Carte.vue'
import ProduitsPage from '../pages/Produits.vue'
import ArmoirePage from '../pages/Armoire.vue'
import Tarificationpage from '../pages/Tarification.vue'
import contactsPage from '../pages/contacts.vue'

import ControlAccesPage from '../pages/ControlAcces.vue'
import HistoriqueControlAccesPage from '../pages/HistoriqueControlAcces.vue'
import TourniquetPage from '../pages/Tourniquet.vue'




import Addcarte from '../pages/Addcarte.vue'
import LoginAdmin from '../pages/LoginAdmin.vue'

import RegisterAdmin from '../pages/RegisterAdmin.vue'

import Bikes from '../pages/Bikes.vue'
import GroupesPage from '../pages/groupesPage.vue'

import SkillRun from '../pages/SkillRun.vue'
import SkillRunStatestiques from '../pages/SkillRunStatestiques.vue'

import SkillAir from '../pages/SkillAir.vue'
import SkillAirStatestiques from '../pages/SkillAirStatestiques.vue'


import InbodyPage from '../pages/InBody.vue'

import Splash from '../pages/splash.vue'

import WebView from '../pages/WebView.vue'

const routes = [


    {
        path: '/',
        component: Splash,
    },
    {
        path: '/LoginAdmin',
        component: LoginAdmin,
    },
    {
        path: '/RegisterAdmin',
        component: RegisterAdmin,
    },

    {
        path: '/carte/add',
        component: Addcarte,
    },
    {
        path: '/ControlAcces/',
        component: ControlAccesPage,
    },
    {
        path: '/HistoriqueControlAcces',
        component: HistoriqueControlAccesPage,
    },
    {
        path: '/tourniquet',
        component: TourniquetPage,
    },
    {
        path: '/accuiel',
        component: AccuielPage
    },
    {
        path: '/profil',
        component: ProfilPage
    },
    {
        path: '/Abonnement',
        component: AbonnementPage
    },
    {
        path: '/CahierDentrainement',
        component: CahierDentrainementPage
    },
    {
        path: '/SuivieAthlete',
        component: SuivieAthletePage
    },
    {
        path: '/Carte',
        component: CartePage
    },
    {
        path: '/Produits',
        component: ProduitsPage
    },
    {
        path: '/Armoire',
        component: ArmoirePage
    },

    {
        path: '/Inbody',
        component: InbodyPage
    },
    {
        path: '/InBodyDetaille/:id',
        props: true,
        component: () =>
            import ('../pages/InBodyDetaille.vue')
    },
    {
        path: '/WebView',
        component: WebView
    },




    {
        path: '/Bikes',
        component: Bikes
    },
    {
        path: '/Groupes',
        component: GroupesPage
    },

    
    {
        path: '/SkillRun',
        component: SkillRun
    },
    {
        path: '/SkillRunStatestiques',
        component: SkillRunStatestiques
    },
    {
    path: '/SkillAir',
    component: SkillAir
},
{
    path: '/SkillAirStatestiques',
    component: SkillAirStatestiques
},

    {
        path: '/Tarification',
        component: Tarificationpage
    },
    {
        path: '/contacts',
        component: contactsPage
    },
    {
        path: '/profile/:id',
        component: () =>
            import ('../pages/Profil.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router