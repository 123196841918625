<template>
<form @submit.prevent="submitForm">

    <ion-card style=" background-color:#151516; ">
        <ion-card-content style="margin:-15px">
            <ion-item counter="true" style=" margin-left:-10px;
            --background: #151516;
            --border-color: #151516;  --color: #fff;
            ">
                <ion-input clear-input="true" mode="ios" class="custom"
                 placeholder="Carte" style="color:white;" maxlength="10" inputmode="numeric" v-model="enteredCarte" />
            </ion-item>
        </ion-card-content>
    </ion-card>

    <ion-card style=" background-color:#151516; ">
        <ion-card-content style="margin:-15px">
            <ion-item counter="true" style=" margin-left:-10px;
            --background: #151516;
            --border-color: #151516;  --color: #fff; ">
                <ion-input clear-input="true" mode="ios" class="custom"
                 placeholder="Id Salle" style="color:white;"   inputmode="numeric" v-model="gymid" />
            </ion-item>
        </ion-card-content>
    </ion-card>
    <ion-button class="ion-button3" type="submit"> OK</ion-button>
</form>
</template>

<script>
import {
    IonInput,
    IonButton,
    IonCard,
    IonItem,
    IonCardContent,
} from '@ionic/vue';
export default {
    data() {
        return {
            enteredCarte: '',
            gymid:'',
        }
    },
    emits: ['save-carte'],
    methods: {
        submitForm() {

            const carteData = {
                carte: this.enteredCarte,
                gymid: this.gymid
            };
            this.$emit('save-carte', carteData);
        },

    },
    components: {
        IonInput,
        IonButton,
        IonCard,
        IonCardContent,
        IonItem
    }
}
</script>

<style>
.ion-button3 {
    --background: #FFE01E;
    --opacity: 0.8;
    height: 40px;
    width: 100%;
    color: #2D2D30;
    font-weight: 700;
    font-size: 15px;
    padding: 0px;
    --border-radius: 10px !important;
}

ion-input.custom {
    width: 100px;

    --color: #fff;
    --placeholder-color: #ddd;

}
</style>
