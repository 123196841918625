<template>
<Base-Layout PageTitle="CONTROLE D'ACCES" page-default-back-Link="/accuiel">
    <br>

    <span class="first-text" style="font-size:14px;color:#d2d2d2">QR CODE

    </span><br>

<br><br>
    
                 <ion-thumbnail class="item-avatar" item-start style="width:400px;height:400px;margin:0px">
                    <img :src="this.QRCODE_URL">
                  
                </ion-thumbnail>

  

</Base-Layout>
</template>

<script>




import {ipServerObjet as ipServerObjet} from './Configserver.js';

export default {
    data() {
        return {
            presentingElement: null,
            ipServer: '',
            qrGeneratorLink:'',
            QRCODE_URL:'',
        };
    },
    async created() {
        for (const property in ipServerObjet) {
            this.ipServer = ipServerObjet[property].ip;
            this.qrGeneratorLink = ipServerObjet[property].qrGeneratorLink;
        }

        this.QRCODE_URL = ""+this.qrGeneratorLink+"?id="+localStorage.getItem('carte');

    },
    methods: {

        
     
    },
    components: {
        

  
    }
}
</script>

<style>
.first-text {
    font-weight: 600;
    font-size: 12px;
}
.clickcard:active {
    background: #FFE01E;
    color: #0e0e0f;
    border-top: 1px solid #22262e;
}

.clickcard:active .first-text {

    --color: #0e0e0f;

}
.second-text {
    color: white;
    font-weight: 600;
    font-size: 15px;
}
</style>
