<template>
   <center><h2 style="color:#f1e501"> <b>historique controle acces</b> </h2></center>
<br>

</template>

<script>
import {


} from '@ionic/vue';

export default{
 components: {

 }
}

</script>
<style>

.first-text{
font-weight:600;font-size:12px;
}
.second-text{
color:white;font-weight:600;font-size:15px;
}

</style>