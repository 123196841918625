<template>
   <center><h2 style="color:#f1e501"> <b>Tourniquets</b> </h2></center>
<br>
        <ion-card style="--ion-background-color: #1d1d1f;">
            <ion-card-content>
                <ion-img src="assets/images/icons/Turnstile2.png" style="object-fit: cover;margin:5px;width:40px;float:left"> </ion-img>
                <span class="first-text" style="font-size:14px" >ENTREE_PRINCIPALE</span><br>
               <span class="second-text" >1</span>
            </ion-card-content>
        </ion-card>
</template>

<script>
import {
    IonCard,
} from '@ionic/vue';

export default{
 components: {
     IonCard
 }
}

</script>
<style>

.first-text{
font-weight:600;font-size:12px;
}
.second-text{
color:white;font-weight:600;font-size:15px;
}

</style>